module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UBS Center | Economics. For Society","short_name":"UBS Center","start_url":"/","background_color":"#fff","theme_color":"#b28e7b","display":"minimal-ui","icon":"logo.png","icons":[{"src":"icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ed079a8ba0bdd9b737638062be01988"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TC9WV83","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

exports.components = {
  "component---src-templates-about-contact-js": () => import("./../../../src/templates/about/contact.js" /* webpackChunkName: "component---src-templates-about-contact-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-about-newsletter-js": () => import("./../../../src/templates/about/newsletter.js" /* webpackChunkName: "component---src-templates-about-newsletter-js" */),
  "component---src-templates-about-people-js": () => import("./../../../src/templates/about/people.js" /* webpackChunkName: "component---src-templates-about-people-js" */),
  "component---src-templates-detail-closer-look-js": () => import("./../../../src/templates/detail/closerLook.js" /* webpackChunkName: "component---src-templates-detail-closer-look-js" */),
  "component---src-templates-detail-event-js": () => import("./../../../src/templates/detail/event.js" /* webpackChunkName: "component---src-templates-detail-event-js" */),
  "component---src-templates-detail-insight-js": () => import("./../../../src/templates/detail/insight.js" /* webpackChunkName: "component---src-templates-detail-insight-js" */),
  "component---src-templates-detail-nobel-opinion-js": () => import("./../../../src/templates/detail/nobelOpinion.js" /* webpackChunkName: "component---src-templates-detail-nobel-opinion-js" */),
  "component---src-templates-detail-policy-brief-js": () => import("./../../../src/templates/detail/policyBrief.js" /* webpackChunkName: "component---src-templates-detail-policy-brief-js" */),
  "component---src-templates-detail-public-paper-js": () => import("./../../../src/templates/detail/publicPaper.js" /* webpackChunkName: "component---src-templates-detail-public-paper-js" */),
  "component---src-templates-detail-researcher-js": () => import("./../../../src/templates/detail/researcher.js" /* webpackChunkName: "component---src-templates-detail-researcher-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-and-events-events-js": () => import("./../../../src/templates/newsAndEvents/events.js" /* webpackChunkName: "component---src-templates-news-and-events-events-js" */),
  "component---src-templates-news-and-events-insights-js": () => import("./../../../src/templates/newsAndEvents/insights.js" /* webpackChunkName: "component---src-templates-news-and-events-insights-js" */),
  "component---src-templates-news-and-events-news-js": () => import("./../../../src/templates/newsAndEvents/news.js" /* webpackChunkName: "component---src-templates-news-and-events-news-js" */),
  "component---src-templates-news-and-events-register-js": () => import("./../../../src/templates/newsAndEvents/register.js" /* webpackChunkName: "component---src-templates-news-and-events-register-js" */),
  "component---src-templates-news-and-events-speakers-js": () => import("./../../../src/templates/newsAndEvents/speakers.js" /* webpackChunkName: "component---src-templates-news-and-events-speakers-js" */),
  "component---src-templates-publications-authors-js": () => import("./../../../src/templates/publications/authors.js" /* webpackChunkName: "component---src-templates-publications-authors-js" */),
  "component---src-templates-publications-closer-look-js": () => import("./../../../src/templates/publications/closerLook.js" /* webpackChunkName: "component---src-templates-publications-closer-look-js" */),
  "component---src-templates-publications-nobel-opinions-js": () => import("./../../../src/templates/publications/nobelOpinions.js" /* webpackChunkName: "component---src-templates-publications-nobel-opinions-js" */),
  "component---src-templates-publications-policy-briefs-js": () => import("./../../../src/templates/publications/policyBriefs.js" /* webpackChunkName: "component---src-templates-publications-policy-briefs-js" */),
  "component---src-templates-publications-public-papers-js": () => import("./../../../src/templates/publications/publicPapers.js" /* webpackChunkName: "component---src-templates-publications-public-papers-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-scholarships-js": () => import("./../../../src/templates/scholarships.js" /* webpackChunkName: "component---src-templates-scholarships-js" */)
}

